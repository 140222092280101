import React from 'react';

export const VerticalDividerCard = ({ colors }) => {
    return (
        <div
            className="w-[0.5px] h-full"
            style={{
                background: `linear-gradient(0deg,  rgba(255,255,255,0), ${colors[1]}, rgba(255,255,255,0) 80%)`,
                // background: `linear-gradient(0deg,  ${colors[0]}, ${colors[1]}, ${colors[2]} 80%)`,
            }}
        ></div>
    );
};
