import styled from "styled-components";

const media = {
  desktop: "@media(min-width : 1023px)",
};

export const NavContainer = styled.ul`
  li {
    ul {
      display: block;
    }
  }
  ${media.desktop} {
    li {
      ul {
        display: none;
      }

      &:hover {
        ul {
          display: block;
        }
      }
    }

    @keyframes ttt {
      0% {
        width: 176px;
      }
      100% {
        width: 96px;
      }
    }

    @keyframes inAnimation {
      0% {
        transform: translateY(-150%);
      }
      100% {
        transform: translateY(0%);
      }
    }

    @keyframes outAnimation {
      0% {
        transform: translateY(0%);
      }
      100% {
        transform: translateY(-150%);
      }
    }
  }

  .animate {
    transition: transform 1s ease-in-out;
  }

  .animate.grow {
    transform: scale(0.7);
  }
`;
