import React from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

// Components
import { Heading } from "@components/Heading";

// Images
import { graphql, useStaticQuery } from "gatsby";

export const DoctorsCard = ({ doctor }) => {
    const {
        placeholder: {
            childImageSharp: { fluid: placeholderImage },
        },
    } = useStaticQuery(
        graphql`
            query {
                placeholder: file(
                    relativePath: { eq: "doctorPlaceholder.jpg" }
                ) {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    const { name, slug, image, specialization } = doctor;

    return (
        <Link className="transition-all" to={`/doctor/${slug}`}>
            <div className="w-full h-330 rounded-2xl overflow-hidden shadow relative transition-all hover:-translate-y-3 hover:shadow-lg">
                <BackgroundImage
                    Tag="section"
                    className="w-full h-full bg-no-repeat bg-cover"
                    fluid={
                        image?.localFile?.childImageSharp?.fluid ||
                        placeholderImage
                    }
                ></BackgroundImage>
                <div className="absolute bottom-0 left-0 w-full bg-white py-3 flex flex-col items-center justify-center">
                    <Heading size={"base"} color={"black"} weight={"medium"}>
                        {name}
                    </Heading>
                    <p className="text-gray-400 text-sm  font-medium mb-0 capitalize">
                        {specialization?.name}
                    </p>
                </div>
            </div>
        </Link>
    );
};
