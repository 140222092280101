import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

// Icons
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { MdClose } from "@react-icons/all-files/md/MdClose";

// Natives
import NavBarLinks from "./NavbarLinks";
import NavBarLogo from "./NavbarLogo";

import { NavContainer } from "./style";

const NavBar = ({ sticky }) => {
    const [showNavBar, setShowNavBar] = useState(false);

    const [slidingOut, setSlidingOut] = useState(false);
    const animateRef = useRef(slidingOut);
    animateRef.current = slidingOut;

    useEffect(() => {
        if (!sticky) {
            setSlidingOut(!animateRef.current);
            const timer = setTimeout(() => {
                setSlidingOut(!animateRef.current);
            }, 300);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [sticky]);

    const classes = classNames({
        "w-full bg-white border-b": true,
        "py-2": !sticky && !slidingOut,
        "fixed top-0 z-30 shadow-xl": sticky || slidingOut,
        "slide-in": sticky,
    });

    return (
        <div className={classes}>
            <div className="flex flex-col lg:flex-row justify-between items-center max-w-[1280px] mx-auto">
                <div className="flex justify-between items-center px-8 lg:px-4 w-full lg:w-auto">
                    <NavBarLogo small={sticky || slidingOut} />
                    <div className="lg:hidden">
                        {!showNavBar ? (
                            <GiHamburgerMenu
                                className="text-3xl"
                                onClick={() => setShowNavBar(true)}
                            />
                        ) : (
                            <MdClose
                                className="text-3xl"
                                onClick={() => setShowNavBar(false)}
                            />
                        )}
                    </div>
                </div>
                {/* <NavBarLogo /> */}

                <nav className={`${showNavBar ? "block" : "hidden"} lg:block`}>
                    {/* Navbar Here */}
                    <NavBarLinks />
                </nav>
            </div>
        </div>
    );
};

export default NavBar;
