import React from 'react';
export const LocationDataCard = ({ data }) => {
    const { Icon, title, text } = data;
    return (
        <div className="h-full flex gap-x-3.5">
            {Icon}
            {/* <Icon className='text-secondary text-xl' /> */}
            <div className="flex flex-col gap-y-2">
                <h4 className="mb-0 text-base font-semibold -mt-1 bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                    {title}
                </h4>
                <p className="text-black text-sm  mb-0 pr-2 whitespace-normal break-all">
                    {text}
                </p>
            </div>
        </div>
    );
};
