import React from "react";

// Icons
import { IoLocationOutline } from "@react-icons/all-files/io5/IoLocationOutline";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { AiOutlinePhone } from "@react-icons/all-files/ai/AiOutlinePhone";
import { FaFax } from "@react-icons/all-files/fa/FaFax";
import { TiPlusOutline } from "@react-icons/all-files/ti/TiPlusOutline";

// Components
import { LocationDataCard } from "@components/Cards";

const LocationData = [
    {
        Icon: <IoLocationOutline className="text-secondary text-xl" />,
        title: "Address",
        text: "14 Broad Oak Drive, Cranbourne East VIC 3977",
    },
    {
        Icon: <AiOutlineMail className="text-secondary text-lg" />,
        title: "Email",
        text: "reception@broadoakmd.com.au",
    },
    {
        Icon: <AiOutlinePhone className="text-secondary text-sm" />,
        title: "Phone",
        text: "(03) 5995 1000",
    },
    {
        Icon: <FaFax className="text-secondary text-sm" />,
        title: "Fax",
        text: "(03) 5995 5136",
    },
    {
        Icon: <TiPlusOutline className="text-secondary text-xl" />,
        title: "A/H Locum Services",
        text: "13-26-60",
    },
];

export const ContactData = () => {
    return (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-4 ">
            {LocationData.map((data, i) => (
                <LocationDataCard key={i} data={data} />
            ))}
        </div>
    );
};

