import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import { Heading } from "@components/Heading";

// Native
import { AboutContainer } from "./style";

const Images = [
    <StaticImage
        src={"../../../images/fastbraces.jpg"}
        alt={"BroadOak MD Dental Services: Fast Braces"}
        className="transition-all duration-300 w-full h-full"
        placeholder="blurred"
    />,
    <StaticImage
        src={"../../../images/medical.jpg"}
        alt={"BroadOak MD Dental Services: Medical"}
        className="transition-all duration-300 w-full h-full"
        placeholder="blurred"
    />,
    <StaticImage
        src={"../../../images/dentistSpecialist.jpg"}
        alt={"BroadOak MD Dental Services: Dentist Specialist"}
        className="transition-all duration-300 w-full h-full"
        placeholder="blurred"
    />,
];

export const AboutCard = ({ about, index }) => {
    const { title, subtitle, url } = about;
    return (
        <Link
            to={url}
            className="w-full h-[250px] rounded-2xl overflow-hidden transition-all relative"
        >
            <AboutContainer>
                <div className="img-container">{Images[index]}</div>

                <div className="aboutdata absolute top-0 left-0 w-full h-full py-4 px-5 flex flex-col justify-end bg-gradient-to-r from-[#4fcffd89] to-[#7b61ff8e]">
                    <p className="text-xl text-white  font-medium mb-0 ">
                        {title}
                    </p>
                    <Heading color={"white"} size={"2xl"} weight={"bold"}>
                        {subtitle}
                    </Heading>
                </div>
            </AboutContainer>
        </Link>
    );
};
