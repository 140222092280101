import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// Icons
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { AiFillInstagram } from "@react-icons/all-files/ai/AiFillInstagram";
import { IoLogoLinkedin } from "@react-icons/all-files/io/IoLogoLinkedin";

// Components
import { FindDoctorsModel } from "@components/FindDoctorsModel";

export const Footer = () => {
    const [findDoctorsModel, setFindDoctorsModel] = useState(false);
    const FooterLinks = [
        {
            link: "/services",
            text: "Services",
        },
        {
            link: "/about-us",
            text: "About Us",
        },
        {
            text: "Find Doctor",
        },
    ];
    return (

        <footer>
            {/* Footer Updated */}
            {/* Footer Top bar */}
            <div className="w-full h-1 bg-gradient-to-r from-secondary to-primary"></div>
            <div className="w-full h-auto pt-4 pb-7 px-2 xl:px-[6.2rem]  bg-gradient-to-r from-primary to-secondary">
                <div className="max-w-1280 h-full mx-auto flex flex-col gap-y-5">
                    <StaticImage
                        src="../../images/footerlogo.svg"
                        className="w-32 mx-auto md:mx-0"
                        objectFit="cover"
                        quality={90}
                        alt="A Gatsby astronaut"
                    />
                    <div className="flex flex-col md:flex-row justify-between items-center gap-y-4">
                        <div className="basis-1/2 flex flex-col md:flex-row justify-between items-center gap-y-4">
                            <div className="flex gap-x-8  text-white">
                                <a
                                    href="https://www.facebook.com/broadoakmd/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaFacebookSquare className="text-2xl" />
                                </a>
                                <a
                                    href="https://www.instagram.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <AiFillInstagram className="text-2xl" />
                                </a>
                                <a
                                    href="https://www.linkedin.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <IoLogoLinkedin className="text-2xl" />
                                </a>
                            </div>
                            <div>
                                <h3 className="text-white text-base text-center md:text-left mb-0">
                                    Quick Links
                                </h3>
                                <ul className="ml-0 mb-0 flex gap-x-3.5">
                                    {FooterLinks.map(({ link, text }, i) => (
                                        <li
                                            key={i}
                                            className="text-white text-sm mb-0"
                                        >
                                            {link ? (
                                                <Link to={link}>{text}</Link>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setFindDoctorsModel(
                                                            true
                                                        );
                                                    }}
                                                    onKeyDown={() => {
                                                        setFindDoctorsModel(
                                                            true
                                                        );
                                                    }}
                                                    role="button"
                                                    tabIndex="0"
                                                >
                                                    {text}
                                                </div>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="basis-1/2">
                            <p className="text-xs text-white text-right font-semibold mb-0">
                                All Rights Reserved © TODDs Group 2022
                            </p>
                        </div>
                    </div>
                </div>

                {/* Model */}
                <FindDoctorsModel
                    findDoctorsModel={findDoctorsModel}
                    setFindDoctorsModel={setFindDoctorsModel}
                />
            </div>
        </footer>
    );
};
