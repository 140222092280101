exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[doctors]-doctors-js": () => import("./../../../src/pages/doctors/[doctors].js" /* webpackChunkName: "component---src-pages-[doctors]-doctors-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-book-appointment-js": () => import("./../../../src/pages/book-appointment.js" /* webpackChunkName: "component---src-pages-book-appointment-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dental-appointment-js": () => import("./../../../src/pages/dental-appointment.js" /* webpackChunkName: "component---src-pages-dental-appointment-js" */),
  "component---src-pages-doctors-index-js": () => import("./../../../src/pages/doctors/index.js" /* webpackChunkName: "component---src-pages-doctors-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medical-appointment-js": () => import("./../../../src/pages/medical-appointment.js" /* webpackChunkName: "component---src-pages-medical-appointment-js" */),
  "component---src-pages-our-services-allied-health-js": () => import("./../../../src/pages/our-services/allied-health.js" /* webpackChunkName: "component---src-pages-our-services-allied-health-js" */),
  "component---src-pages-our-services-dentist-js": () => import("./../../../src/pages/our-services/dentist.js" /* webpackChunkName: "component---src-pages-our-services-dentist-js" */),
  "component---src-pages-our-services-doctors-js": () => import("./../../../src/pages/our-services/doctors.js" /* webpackChunkName: "component---src-pages-our-services-doctors-js" */),
  "component---src-pages-our-services-index-js": () => import("./../../../src/pages/our-services/index.js" /* webpackChunkName: "component---src-pages-our-services-index-js" */),
  "component---src-pages-our-services-specialist-js": () => import("./../../../src/pages/our-services/specialist.js" /* webpackChunkName: "component---src-pages-our-services-specialist-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-doctor-details-js": () => import("./../../../src/templates/doctor-details.js" /* webpackChunkName: "component---src-templates-doctor-details-js" */),
  "component---src-templates-service-details-js": () => import("./../../../src/templates/service-details.js" /* webpackChunkName: "component---src-templates-service-details-js" */)
}

