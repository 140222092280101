import React from "react";
import moment from "moment";

// Components
import { Heading } from "@components/Heading";

export const RoasterCard = ({ openingHour, day }) => {
  const { opening, closing, name } = openingHour;
  return (
    <div className="w-full rounded-2xl p-2 border border-secondary overflow-hidden relative">
      <Heading alignment={"center"} color={"black"} weight={"bold"} size={"sm"}>
        {name}
      </Heading>
      <div className="w-full flex flex-col justify-center items-center">
        <p className="text-center font-semibold text-lg">
          {moment(opening, "hh:mm:ss").format("h:mm a")} -{" "}
          {moment(closing, "hh:mm:ss").format("h:mm a")}
        </p>
      </div>
    </div>
  );
};
