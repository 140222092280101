import React from "react";
import { Link } from "gatsby";
import "./style.css";

export const Button = ({
    label,
    size,
    color,
    border,
    type,
    skew,
    animated,
    link,
}) => {
    const colorClasses = {
        white: "bg-white text-secondary",
        blue: "bg-gradient-to-r from-primary to-secondary text-white border border-transparent",
        animated: "animated-gradient text-white border border-transparent",
        animatedInv:
            "animated-gradient-inv text-secondary border border-transparent",
    };

    const hoverClasses = {
        white: "hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white",
        blue: "hover:bg-bg-gradient-to-r hover:from-white hover:to-white hover:text-secondary hover:border-secondary",
        animated: "hover:border-secondary hover:text-secondary",
        animatedInv: "hover:border-white hover:text-white",
    };

    const sizeClasses = {
        sm: "px-2.5 py-0.5 text-sm",
        md: "px-4 py-1.5 text-base",
        lg: "px-7 py-1.5 text-base",
        xl: "px-8 py-2 text-sm",
    };

    const borderClasses = {
        border: "border border-secondary",
    };

    const skewclasses = {
        skew: "-skew-x-12",
    };
    return link ? (
        <Link
            to={link}
            className={`whitespace-nowrap rounded-full uppercase text-xs lg:text-sm font-medium
		${colorClasses[color]} 
		${sizeClasses[size]} 
		${borderClasses[border || ""]}
		${skewclasses[skew || ""]}
		${hoverClasses[color]}
		`}
        >
            {label}
        </Link>
    ) : (
        <button
            type={type || "button"}
            className={`transition-all whitespace-nowrap rounded-full text-xs lg:text-sm font-medium
      ${colorClasses[color]} 
      ${sizeClasses[size]} 
      ${borderClasses[border || ""]}
      ${skewclasses[skew || ""]}
      ${hoverClasses[color]}
      `}
        >
            {label}
        </button>
    );
};
