import React from "react";
import { Link } from "gatsby";

// Components
import { Button } from "@components/Button";
import { Heading } from "@components/Heading";

export const TitleAndButton = ({
    topLabel,
    bottomLabel,
    buttonLink,
    button = true,
}) => {
    return (
        <div className="w-full flex items-center justify-between">
            <div className="flex gap-x-2 lg:gap-x-4">
                {/* colored Bar */}
                <div className="w-[25px] lg:w-[100px] h-[33px] bg-gradient-to-r from-primary to-secondary"></div>
                <div className="flex flex-col">
                    <p className="text-xl lg:text-3xl font-semibold -mt-2 lg:mt-0">
                        {topLabel}
                    </p>
                    {bottomLabel && (
                        <div className="-mt-2 lg:-mt-0">
                            <Heading
                                size={"4xl"}
                                weight={"bold"}
                                transform={"capitalize"}
                            >
                                {bottomLabel}
                            </Heading>
                        </div>
                    )}
                </div>
            </div>

            {button && (
                <div className="mr-2">
                    <Button
                        label={"View All"}
                        size={"lg"}
                        color={"animatedInv"}
                        border={"border"}
                        link={buttonLink || "#"}
                    />
                </div>
            )}
        </div>
    );
};
