import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

export const HeroSliderCard = ({ slug, name, image, specialization }) => {
    const imageSrc = image?.localFile ? getImage(image.localFile) : null;

    const { placeholder } = useStaticQuery(
        graphql`
            query {
                placeholder: file(
                    relativePath: { eq: "doctorPlaceholder.jpg" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: DOMINANT_COLOR
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        `
    );
    const placeHolderImage = getImage(placeholder);

    return (
        <div className="w-full h-[413px] rounded-2xl overflow-hidden relative">
            <GatsbyImage
                image={imageSrc || placeHolderImage}
                alt={slug}
                className="object-cover w-full h-full"
            />

            <div
                className="absolute bottom-0 left-0 w-full h-[120px] p-2.5 flex items-end"
                style={{
                    background:
                        "linear-gradient(180deg, rgba(79, 207, 253, 0) 0%, #4FCFFD 100%)",
                }}
            >
                <Link to={`doctor/${slug}`}>
                    <p className="text-xl lg:text-sm text-sky-50 font-medium">
                        {name}
                    </p>

                    {specialization && (
                        <p className="text-md lg:text-xs text-sky-600">
                            {specialization.name}
                        </p>
                    )}
                </Link>
            </div>
        </div>
    );
};
