import React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const NavBarSubLink = ({ data }) => {
    // const image = getImage(data.image.localFile);
    const image = data.image;
    return (
        <li className="py-2 px-2 text-black lg:text-secondary rounded-lg hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white">
            {/* <Link to={`/${data.url}/${data.slug}`} className=""> */}
            <Link to={`${data.slug}`} className="">
                <div className="flex items-center gap-x-2 group">
                    {/* <GatsbyImage
                        image={image}
                        alt={data.image.alternativeText}
                        className="w-12 h-12 rounded-md"
                    /> */}
                    {/* <StaticImage
                        src={image}
                        alt={data.name}
                        className="w-120 h-120 rounded-full mx-auto -mt-16 border-8 object-cover border-[#FFFEFE]"
                        placeholder="blurred"
                    /> */}
                    <div>
                        <p className="text-sm font-medium">{data.name}</p>
                        <p className="text-gray-500 group-hover:text-sky-300 text-xs font-normal ">
                            {data.shortDescription}
                        </p>
                    </div>
                </div>
            </Link>
        </li>
    );
};

export default NavBarSubLink;
