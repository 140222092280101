import styled from "styled-components";

export const TeamCardContainer = styled.div`
  .socialIcons {
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    .icon {
      transform: translateY(300%);
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 300ms;
    }
  }

  &:hover {
    .socialIcons {
      opacity: 1;

      .icon {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 300ms;
        transform: translateY(0);
      }
    }
  }
`;
