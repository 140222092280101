import moment from "moment";
import React from "react";
import removeMd from "remove-markdown";

// Components
import { Button } from "@components/Button";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const BlogCard = ({ blog }) => {
    const {
        slug,
        headline,
        content: { data, medias },
        publishedAt,
    } = blog;

    const plainText = removeMd(data.content, {
        useImgAltText: false, // replace images with alt-text, if present (default: true)
    });

    const { placeholder } = useStaticQuery(
        graphql`
            query {
                placeholder: file(relativePath: { eq: "placeholder.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: DOMINANT_COLOR
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        `
    );
    const placeHolderImage = getImage(placeholder);
    const blogImage = medias?.length
        ? getImage(medias[0].localFile)
        : placeHolderImage;

    return (
        <div className="w-full mx-auto rounded-[1.25rem] shadow-md mt-20 py-3 px-6">
            {/* <img
                className="w-full h-[130px] rounded-2xl -mt-20 object-cover"
                src={content.medias[0]?.file?.url || BlogPlaceholder}
                alt={headline}
            /> */}
            <GatsbyImage
                image={blogImage}
                alt={headline}
                className="w-full h-[130px] rounded-2xl -mt-20 object-cover"
            />

            <div className="pt-5 pl-3.5 pr-2.5 relative">
                <p className="text-[#B7B5B5] text-xs  mb-0">
                    {moment(publishedAt, "YYYYMMDD").format("MMM DD, YYYY")}
                </p>

                <p className="capitalize">
                    {headline.length > 30
                        ? `${headline.substring(0, 30)}...`
                        : headline}
                </p>

                <p className="text-sm text-[#757575]  mb-0">
                    {plainText.length > 40
                        ? `${plainText.substring(0, 40)}...`
                        : plainText}
                </p>
                <div className="ml-auto max-w-max mt-1">
                    <Button
                        label={"Read More"}
                        color={"white"}
                        size={"md"}
                        link={`/blog/${slug}`}
                    />
                </div>
            </div>
        </div>
    );
};
