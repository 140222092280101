import React from "react";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";

const NavBarLogo = ({ small }) => {
    const classes = classNames({
        "object-contain": true,
        "w-16 lg:w-32": !small,
        "w-16": small,
    });
    return (
        <Link to="/" className="h-full p-2">
            {small ? (
                <StaticImage
                    src={"../../images/small_logo.svg"}
                    alt="BroadOak Md Logo"
                    className={classes}
                    placeholder="blurred"
                />
            ) : (
                <StaticImage
                    src={"../../images/logo.svg"}
                    alt="BroadOak Md Logo"
                    className={classes}
                    placeholder="blurred"
                />
            )}
        </Link>
    );
};

export default NavBarLogo;
