import React from 'react';
export const Heading = ({
    children,
    size,
    color,
    weight,
    alignment,
    transform,
    height = false,
}) => {
    const sizeClasses = {
        xs: 'text-xs',
        sm: 'text-sm',
        base: 'text-base',
        lg: 'text-lg',
        xl: 'text-xl',
        '2xl': 'text-2xl',
        '3xl': 'text-3xl',
        '4xl': 'text-2xl md:text-4xl',
        '5xl': 'text-2xl sm:text-4xl md:text-5xl',
        1.75: 'text-[1.75rem]',
        1.68: 'text-[1.688rem]',
        2: 'text-2xl md:text-2',
        '2.6' : 'text-[2.688rem]'
    };

    const colorClasses = {
        gradient:
            'bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary',
        black: 'text-black',
        white: 'text-white',
        dark_gray: 'text-[#2D2D2D]',
    };

    const weightClasses = {
        normal: 'font-normal',
        medium: 'font-medium',
        bold: 'font-bold',
    };

    const alignmentClasses = {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
    };

    const transformclasses = {
        capitalize: 'capitalize',
        uppercase: 'uppercase',
        none: 'normal-case',
    };

    return (
        <h2
            className={`
            ${sizeClasses[size || 'base']} 
            ${colorClasses[color || 'gradient']} 
            ${weightClasses[weight || 'normal']} 
            ${alignmentClasses[alignment || 'left']}
            ${transformclasses[transform || 'none']}
            ${height && 'h-9'}
             mb-0 break-all 
            `}
        >
            {children}
        </h2>
    );
};
