import React from "react";
import { Link } from "gatsby";

// Components
import { Button } from "@components/Button";
import { Heading } from "@components/Heading";

// Natives
import { CardContainer } from "./style";

// Images
import doctorPlaceholder from "@images/doctorPlaceholder.jpg";

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

export const DoctorPageCard = ({ doctor }) => {
    const { name, qualification, specialization, image } = doctor;

    const {
        placeholder: { childImageSharp: placeholderImage },
    } = useStaticQuery(
        graphql`
            query {
                placeholder: file(
                    relativePath: { eq: "doctorPlaceholder.jpg" }
                ) {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: DOMINANT_COLOR
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        `
    );

    const imageSrc = image
        ? getImage(image.localFile)
        : getImage(placeholderImage);

    return (
        <div className="w-full">
            <CardContainer className="w-full h-[375px] rounded-3xl overflow-hidden relative">
                {/* <img
                    className="w-full h-full object-cover"
                    src={image?.url || doctorPlaceholder}
                    alt={name}
                /> */}
                <GatsbyImage
                    image={imageSrc}
                    alt={name}
                    className="w-full h-full object-cover"
                />

                <div className="doctors_data absolute bottom-0 left-0 w-full h-[123px] flex flex-col justify-center items-center bg-gradient-to-t from-secondary to-[#C4C4C400]">
                    <Heading
                        color={"white"}
                        size={"2xl"}
                        alignment={"center"}
                        weight={"bold"}
                    >
                        {name}
                    </Heading>
                    <p className="text-center text-white text-xl ">
                        {qualification}
                    </p>

                    <p className="text-center text-white text-xl capitalize">
                        {specialization?.name}
                    </p>
                </div>
            </CardContainer>

            <div className="flex justify-between items-center pt-4">
                <Button
                    link={"/book-appointment"}
                    label={"Book"}
                    size={"lg"}
                    color={"blue"}
                />
                <Button
                    label={"Learn More"}
                    size={"lg"}
                    color={"white"}
                    border={"border"}
                    link={`/doctor/${doctor.slug}`}
                />
            </div>
        </div>
    );
};
