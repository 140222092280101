import React, { useEffect, useState, useCallback } from "react";
import { useInView } from "react-intersection-observer";

import { isBrowser } from "@utils";
import NavBar from "./Navbar";

export const Header = () => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    });

    const [y, setY] = useState(isBrowser() ? window.scrollY : 0);
    const [goingUp, setGoingUp] = useState(false);

    const handleNavigation = useCallback(
        (e) => {
            const window = e.currentTarget;
            if (y > window.scrollY && !goingUp) {
                setGoingUp(true);
            } else if (y < window.scrollY && goingUp) {
                setGoingUp(false);
            }
            setY(window.scrollY);
        },
        [y]
    );

    useEffect(() => {
        if (isBrowser()) {
            setY(window.scrollY);
            window.addEventListener("scroll", handleNavigation);

            return () => {
                window.removeEventListener("scroll", handleNavigation);
            };
        }
    }, [handleNavigation]);

    return (
        <div className={`w-full lg:h-20 mb-2 lg:mb-12`} ref={ref}>
            <NavBar sticky={!inView && goingUp} />
        </div>
    );
};
