import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

// Components
import { Header } from "@components/Header";
import Location from "@components/Location/Location";
import { Footer } from "@components/Footer";

// Natives
import "./layout.css";

const Layout = ({ children }) => {
	useEffect(() => {
		Aos.init({ duration: 1500, once: true });
	}, []);

	return (
		<>
			<Header />
			{children}
			<Location />
			<Footer />
		</>
	);
};

export default Layout
