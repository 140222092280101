import styled from "styled-components";

export const AboutContainer = styled.div`
    .img-container {
        transition: all 0.3s ease-in-out;
    }

    .aboutdata {
        opacity: 0;
        transition: opacity 500ms ease-in-out;

        p {
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 500ms;
            transform: translateY(300%);
        }
        h2 {
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 400ms;
            transform: translateY(300%);
        }
    }

    &:hover {
        .img-container {
            transform: scale(1.1);
        }

        .aboutdata {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;

            p {
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 400ms;
                transform: translateY(0);
            }
            h2 {
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 550ms;
                transform: translateY(0);
            }
        }
    }
`;
