import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { FindDoctorsModel } from "@components/FindDoctorsModel";
import classNames from "classnames";

import NavBarSubLink from "./NavbarSubLink";
import { Button } from "@components/Button";

const NavBar = () => {
    // Fetch services from Strapi
    const serviceQueryResult = useStaticQuery(graphql`
        query ServicesQuery {
            allStrapiService {
                nodes {
                    slug
                    name
                    shortDescription
                    image {
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 48
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                    sample
                }
            }
        }
    `);

    const [findDoctorsModel, setFindDoctorsModel] = useState(false);

    // const services = serviceQueryResult.allStrapiService.nodes.filter(
    //     (service) => !service.sample
    // );

    const services = serviceQueryResult.allStrapiService.nodes.filter(
        (service) => !service.sample
    );

    const servicesX = [
        {
            slug: "/our-services/allied-health",
            name: "Allied Health",
            shortDescription: "Services for Allied Health",
            image: "../../images/pages/medical.jpeg",
        },
        {
            slug: "/our-services/dentist",
            name: "Dental Service",
            shortDescription: "Take care of your teeth",
            image: "../../images/pages/dentists.jpeg",
        },
        {
            slug: "/our-services/doctors",
            name: "Doctors",
            shortDescription: "Practicing Doctors",
            image: "../../images/pages/doctors.jpeg",
        },
        {
            slug: "/our-services/specialist",
            name: "Specialist",
            shortDescription: "Visiting Doctors",
            image: "../../images/pages/doctors.jpeg",
        },
    ];

    const appointments = [
        {
            slug: "/medical-appointment",
            name: "Medical Appointment",
            shortDescription: "Online Medical Bookings",
            // image: "../../images/pages/medical.jpeg",
        },
        {
            slug: "/dental-appointment",
            name: "Dental Appointment",
            shortDescription: "Online Dental Bookings",
        },
    ];

    // Navbar Items
    const NavItems = [
        {
            // to: "/services",
            to: "/our-services/allied-health",
            label: "Services",
            subItems: [...servicesX],
            type: "link",
        },
        {
            to: "/about-us",
            label: "About Us",
            type: "link",
        },
        {
            label: "Find Doctor",
            onClick: () => {
                setFindDoctorsModel(true);
            },
            type: "link",
        },
        {
            to: "/blogs",
            label: "Blogs",
            type: "link",
        },
        {
            to: "/contact-us",
            label: "Contact Us",
            type: "link",
        },
        {
            to: "/book-appointment/",
            label: "Book Appointment",
            subItems: [...appointments],
            type: "button",
        },
    ];

    const navLinkClasses = classNames({
        // Large Devices
        "font-bold relative z-10 transition-all duration-300 py-4 flex flex-col item-center px-2 text-sm": true,
        "text-transparent bg-clip-text bg-gradient-to-r from-black to-black cursor-pointer": true,
        "hover:from-primary hover:to-secondary": true,
    });

    const linkDropDownClasses = classNames({
        // Default
        "hidden group-hover:block top-full left-0 mt-0 w-60 z-30 bg-white shadow-xl rounded-b-xl": true,

        // For Smaller Devices
        "p-1": true,

        // For Large Devices
        " lg:ml-0 lg:absolute ": true,
    });

    const getLinkType = (navLink) => {
        if (navLink.type === "button") {
            return (
                <Button
                    label={navLink.label}
                    color={"animated"}
                    size={"md"}
                    link={navLink.to}
                />
            );
        } else if (navLink.type === "link") {
            if (navLink.onClick) {
                return (
                    <div
                        onClick={navLink.onClick}
                        onKeyDown={navLink.onClick}
                        role="button"
                        tabIndex="0"
                    >
                        {navLink.label}
                    </div>
                );
            } else {
                return (
                    <Link
                        to={navLink.to}
                        activeClassName="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary"
                    >
                        {navLink.label}
                    </Link>
                );
            }
        }
    };

    return (
        <ul className="flex flex-col lg:flex-row items-center justify-center">
            {NavItems.map((navLink, i) => (
                <li
                    key={i}
                    className={`${
                        navLink.subItems ? "group" : ""
                    } ${navLinkClasses}`}
                >
                    {getLinkType(navLink)}

                    {navLink.subItems && navLink.subItems.length > 0 && (
                        <ul className={linkDropDownClasses}>
                            {navLink.subItems.map((subItem, innerIndex) => (
                                <NavBarSubLink
                                    key={innerIndex}
                                    data={subItem}
                                />
                            ))}
                        </ul>
                    )}
                </li>
            ))}

            {/* Model */}
            <FindDoctorsModel
                findDoctorsModel={findDoctorsModel}
                setFindDoctorsModel={setFindDoctorsModel}
            />
        </ul>
    );
};
export default NavBar;
