import React from 'react';

export const HorizontalDividerCard = ({ colors }) => {
    const style = {
        border: '0',
        height: '0.75px',
        width: '100%',
        // backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255), rgba(255, 255, 255, 0)`,
        backgroundImage: `linear-gradient(to right, ${colors[0]}, ${colors[1]}, ${colors[2]}`,
    };
    return <div style={style}> </div>;
};
