import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";

// Icons
import { GiCancel } from "@react-icons/all-files/gi/GiCancel";

// components
import { Link } from "gatsby";
import { useOnClickOutside } from "../ClickOutSide";

// Natives
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        zIndex: 51,
        background: "transparent",
    },
};

export const FindDoctorsModel = ({ setFindDoctorsModel, findDoctorsModel }) => {
    const [doctors, setDoctors] = useState([]);
    const [doctorSpecialization, setDoctorSpecialization] = useState([]);
    const ref = useRef(null);
    useEffect(() => {
        const getServices = async () => {
            try {
                const { data } = await axios.get(
                    "https://api-broadoakmd.herokuapp.com/api/doctors?populate=*"
                );
                setDoctors(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        getServices();
    }, []);

    useEffect(() => {
        if (doctors && doctors.length > 0) {
            const specializations = [
                ...new Set(
                    doctors.map(
                        ({ attributes }) =>
                            attributes?.specialization?.data?.attributes?.name
                    )
                ),
            ];
            setDoctorSpecialization(
                specializations.filter((s) => s !== undefined)
            );
        }
    }, [doctors]);

    const DoctorSpecialization = () => {
        setFindDoctorsModel(false);
    };

    function closeModal() {
        setFindDoctorsModel(false);
    }

    // Closing Category And Languange Selector Dropdown
    useOnClickOutside(ref, () => {
        setFindDoctorsModel(false);
    });
    return (
        <Modal
            isOpen={doctors.length > 0 ? findDoctorsModel : false}
            onRequestClose={closeModal}
            ariaHideApp={false}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="w-full h-screen flex justify-center items-center outsideClick">
                <div
                    ref={ref}
                    className="w-full md:w-2/3 lg:w-2/5 max-h-[400px] overflow-auto bg-[#ffffff30] rounded-lg relative backdrop-blur-md shadow-lg"
                >
                    <div className="w-full h-auto">
                        <div className="w-full p-3 bg-gradient-to-r from-primary to-secondary flex justify-between items-center">
                            <p className="text-white font-semibold">What are you looking for?</p>

                            <GiCancel
                                className=" text-white text-3xl cursor-pointer"
                                onClick={() => setFindDoctorsModel(false)}
                            />
                        </div>

                        <div className="flex flex-col items-center justify-center gap-y-5 p-5 ">
                            {doctorSpecialization.length > 0 &&
                                doctorSpecialization.map(
                                    (specialization, i) => (
                                        <Link
                                            key={i}
                                            to={`/doctors/${specialization
                                                .replace(" ", "-")
                                                .toLowerCase()}`}
                                            className="bg-transparent rounded-full border border-white text-white hover:bg-[#ffffff40] w-full py-4 px-5 cursor-pointer"
                                            onClick={DoctorSpecialization}
                                        >
                                            <p className="capitalize">
                                                {specialization}
                                            </p>
                                        </Link>
                                    )
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
