import React from "react";
// Icons

// Components
import { TitleAndButton } from "@components/TitleAndButton";

import { isBrowser } from "@utils";
import { ContactData } from "./ContactData";

const Location = () => {
    if (!isBrowser()) {
        return null;
    }

    const pagesUrl = ["/about-us", "/contact-us"];

    return (
        <div className="w-full py-16" data-aos="fade-up">
            {!pagesUrl.includes(window.location.pathname) && (
                <TitleAndButton
                    topLabel={"Our"}
                    bottomLabel={"Location"}
                    button={false}
                />
            )}

            {/* <div className='w-full px-2 xl:px-[5.7rem]'> */}
            <div className="w-full px-2 xl:px-[3.8rem]">
                <div className="max-w-1280 mx-auto flex flex-col md:flex-row justify-between gap-y-5 pt-10">
                    {/* Data */}
                    <div className="basis-[52%] xl:pl-[1.9rem]">
                        {/* {LocationData.map((data, i) => (
                            <LocationDataCard key={i} data={data} />
                        ))} */}
                        <ContactData />
                    </div>

                    {/* Map */}
                    <div className="basis-[45%] h-[500px] sm:h-[333px] rounded-2xl overflow-hidden">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3139.6631397929796!2d145.30036761517098!3d-38.101504079701336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad60fe4c591f4f7%3A0x940276861be2eb3c!2s14%20Broad%20Oak%20Dr%2C%20Cranbourne%20East%20VIC%203977%2C%20Australia!5e0!3m2!1sen!2s!4v1653710768620!5m2!1sen!2s"
                            className="w-full h-full"
                            title="maps"
                            height="600px"
                            style={{ border: "0" }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
