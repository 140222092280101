import styled from "styled-components";

export const CardContainer = styled.div`
  .doctors_data {
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    p {
      transform: translateY(300%);
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 400ms;
    }
    h2 {
      transform: translateY(300%);
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 550ms;
    }
  }

  &:hover {
    .doctors_data {
      opacity: 1;
      transition: opacity 300ms ease-in-out;

      h2 {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 400ms;
        transform: translateY(0);
      }
      p {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 550ms;
        transform: translateY(0);
      }
    }
  }
`;
