import React from 'react';
import moment from 'moment';

export const TimingCard = ({
    timing
}) => {
    const { opening, closing, name } = timing;

    return (
        <div>
            <p className="text-sm text-white mb-0 capitalize">
                {name}
            </p>
            <p className="text-sm font-semibold text-white mb-0">
                {moment(opening, 'hh:mm:ss').format('h:mm a')} -{' '}
                {moment(closing, 'hh:mm:ss').format('h:mm a')}
            </p>
        </div>
    );
};
